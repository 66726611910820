<template>
  <div class="title">
    <ReflectLink
      v-if="withBackButton"
      :query-params-to-keep="queryParamsToKeep"
      :link="link || '.'"
      class="title__back"
    >
      <i class="back__icon icon-arrow" />
    </ReflectLink>
    <h1 class="title__text">
      {{ text }}
    </h1>
  </div>
</template>

<script lang="ts">
import type { QUERY_PARAMETERS } from '~/types/queryParameters'

export default {
  name: 'ReflectTitle',
  props: {
    text: {
      type: String,
      default: ''
    },
    withBackButton: {
      type: Boolean,
      default: false
    },
    queryParamsToKeep: {
      type: [Array, null] as PropType<QUERY_PARAMETERS[] | null>,
      default: null
    },
    link: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: inline-flex;
  margin-bottom: $margin-regular;

  &__back {
    display: flex;
    align-self: center;
    color: $text-secondary;
    margin-right: $margin-small;

    .back {
      &__icon {
        @include font-icon;
        @include font-size($font-size-extra-large);
      }
    }
  }

  &__text {
    @include font-text($font-weight-bold);
    @include font-size($font-size-extra-large);
    color: $text-primary;
    cursor: default;

    &::selection {
      background: transparent;
    }
  }
}
</style>
